// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDtkBoYeYQxNRhEiFIdxJnpykV45EWWRVk",
  authDomain: "react-chat-app-e77ca.firebaseapp.com",
  databaseURL: "https://react-chat-app-e77ca.firebaseio.com",
  projectId: "react-chat-app-e77ca",
  storageBucket: "react-chat-app-e77ca.appspot.com",
  messagingSenderId: "959618996918",
  appId: "1:959618996918:web:4d1c91eb470df66c742417"
};


const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const storageRef = firebase.storage().ref();

export { db, auth, storage, storageRef };
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_API_KEY,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASE_URL,
//   projectId: process.env.REACT_APP_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_APP_ID,
// };



