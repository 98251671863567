import React, { useContext } from "react";
import { Link } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { selectUserAddress } from "./features/userSlice";
import { auth, db } from "../firebase";
import { signOut } from "firebase/auth";
import { updateDoc, doc } from "firebase/firestore";
import { AuthContext } from "../context/auth";
import { useHistory } from "react-router-dom";

const Navbar = () => {
  const history = useHistory();
  const { user , setUser ,loggedin , setLoggedin } = useContext(AuthContext);
  // var useraddress = useSelector(selectUserAddress);
  
const handleSignout = async () => {
  await updateDoc(doc(db, "users", user), {
    isOnline: false,
  });
  await signOut(auth);
   sessionStorage.clear("meta-address");
   setUser(null);
   setLoggedin(false);
    history.replace("/");
    // window.location.reload();
};
console.log(user);
  
  return (
    <nav>
      <h1 id = "sai_messenger">
        {loggedin ? <Link to="/">Sai Messenger</Link> : <Link to="/login">Sai Messenger</Link> }
      </h1>
      <div>
        {loggedin &&  
          <>
            <Link to="/profile">Profile</Link>
            <button className="btn" onClick={handleSignout}>
              Logout
            </button>
          </>
        
        }
      </div>
    </nav>
  );
};

export default Navbar;
