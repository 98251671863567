//import Modal from '../components/modals/modal'
import React, { useEffect, useState , useContext} from "react";
import { db,  storage } from "../firebase";
//import { db, auth, storage } from "../firebase";
import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  Timestamp,
  orderBy,
  setDoc,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import User from "../components/User";
import MessageForm from "../components/MessageForm";
import Message from "../components/Message";
import { AuthContext } from "../context/auth";

const Home = () => {
  const [users, setUsers] = useState([]);
  const [chat, setChat] = useState("");
  const [text, setText] = useState("");
  const [img, setImg] = useState("");
  const [msgs, setMsgs] = useState([]);
  const [search , setSearch] = useState([]);
  const { user } = useContext(AuthContext);
  const {  setImage} = useContext(AuthContext);
  //const { image , setImage} = useContext(AuthContext);

  console.log(user)
  const user1 = user;
console.log(user1);
  useEffect(() => {
    const usersRef = collection(db, "users");
    // create query object
    const q = query(usersRef, where('__name__', "not-in", [user1]));
    // execute query
    const unsub = onSnapshot(q, (querySnapshot) => {
      let users = [];
      querySnapshot.forEach((doc) => {
        users.push({ id: doc.id, ...doc.data() });
      });
      setUsers(users);
      console.log('users',users);
    });
    return () => unsub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectUser = async (usr) => {
    console.log(usr);
    setChat(usr);
    
    const user2 = usr.id;
    console.log("usr2",user2);
    const id = user1 > user2 ? `${user1 + user2}` : `${user2 + user1}`;

    const msgsRef = collection(db, "messages", id, "chat");
    const q = query(msgsRef, orderBy("createdAt", "asc"));

    onSnapshot(q, (querySnapshot) => {
      let msgs = [];
      querySnapshot.forEach((doc) => {
        msgs.push({ id: doc.id, ...doc.data() });
      });
      setMsgs(msgs);
    });

    // get last message b/w logged in user and selected user
    const docSnap = await getDoc(doc(db, "lastMsg", id));
    // if last message exists and message is from selected user
    if (docSnap.data() && docSnap.data().from !== user1) {
      // update last message doc, set unread to false
      await updateDoc(doc(db, "lastMsg", id), { unread: false });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const user2 = chat.id;
    console.log("chat",chat);
    console.log("user1",user1);

     console.log("user2",user2);
    const id = user1 > user2 ? `${user1 + user2}` : `${user2 + user1}`;
   console.log("id",id);
    let url;
    if (img) {
      const imgRef = ref(
        storage,
        `images/${new Date().getTime()} - ${img.name}`
      );
      setImage(false);
      const snap = await uploadBytes(imgRef, img);
      const dlUrl = await getDownloadURL(ref(storage, snap.ref.fullPath));
      url = dlUrl;
    }

    await addDoc(collection(db, "messages", id, "chat"), {
      text,
      from: user1,
      to: user2,
      createdAt: Timestamp.fromDate(new Date()),
      media: url || "",
    });

    await setDoc(doc(db, "lastMsg", id), {
      text,
      from: user1,
      to: user2,
      createdAt: Timestamp.fromDate(new Date()),
      media: url || "",
      unread: true,
    });

    setText("");
    setImg("");
  };
  return (
    <div className="home_container" >
     
      {/* <div className="home_left">
        <div className="Search_box"></div>
      </div> */}
      <div className="users_container">
      <div >
        <input
        className="Search_box"
          type="text"
          placeholder="Search or Start new chat"
          value={search}
          onChange={(e) => setSearch(e.target.value) }
        />
        
      </div>
        {users.map((usr) => (
          <User
            key={usr.__name__}
            user={usr}
            selectUser={selectUser}
            user1={user1}
            chat={chat}
          />
        ))}
      </div>
      <div className="messages_container" >
        {chat ? (
          <>
            <div className="messages_user" >
             <h3>{chat.Firstname} &nbsp; {chat.Lastname} &nbsp; </h3>
             
              {/* <h6></h6> */}
            </div>
            <div className="messages" >
              {msgs.length
                ? msgs.map((msg, i) => (
                    <Message key={i} msg={msg} user1={user1} />
                  ))
                : null}
                  
            </div>
            <MessageForm
              handleSubmit={handleSubmit}
              text={text}
              setText={setText}
              setImg={setImg}
              
            />
        
          </>
        ) : (
          <h3 className="no_conv">Select a user to start conversation </h3>
          
        )}
      </div>
    </div>
  );
};

export default Home;
