import { createContext, useEffect, useState } from "react";
//import { onAuthStateChanged } from "firebase/auth";
//import { auth } from "../firebase";
import Loading from "../components/Loading";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loggedin , setLoggedin] = useState(false);
  const [image , setImage] = useState(false);

  useEffect(() => {
    console.log(user);
      setUser(user);
      setLoading(false);
    }, [user , loggedin ]);
  if (loading) {
    return <Loading />;
  }
  return (
    <AuthContext.Provider value={{ user , setUser , loggedin , setLoggedin , image , setImage}}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
