import { React , useState , useEffect , useContext} from "react";
import Card from "../components/Card/Card";
import Signup from "../components/Signup/Signup";
// import Register from "./Register";
import classes from "./Login.module.css";
import { db } from "../firebase";
// import { useDispatch } from "react-redux";
// import { setUser } from "../components/features/userSlice";
import { useCallback } from "react";
import { useHistory } from 'react-router-dom';
import { updateDoc } from "firebase/firestore";
import { AuthContext } from "../context/auth";

let acc;

const Login = (props) => {
  const [isConnecting, setIsConnecting] = useState(false);
  const [userAddress, setUserAddress] = useState(null);
  const [show2, setShow2] = useState(false);
  const {user , setUser } = useContext(AuthContext);
  const {setLoggedin} = useContext(AuthContext);
  //const {loggedin , setLoggedin} = useContext(AuthContext);
  // const dispatch = useDispatch();
  

  const history = useHistory();

  const handleRef = useCallback(async (userAddress) => { 
    if (userAddress) {
      var userDocRef = db.collection("users").doc(userAddress);
      await userDocRef.get().then((doc) => {
        if (doc.exists) {
          console.log("Document exists");
          updateDoc(userDocRef, {
            isOnline: true,
            
          });
          setUser(userAddress);
          setLoggedin(true);
          history.replace('/');
        } else {
          // setUser(userAddress);
        
          console.log(userAddress);
          setShow2(true);
          setUserAddress(null)
          console.log(user);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setShow2]);
  
  //Creating metamask button
  const MetamaskHandler = useCallback( async () => {
    if (typeof window.ethereum !== "undefined") {
      setIsConnecting(true);
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
    });
      setIsConnecting(false);
      const account = accounts[0];
      alert("You Are Connected Now", account);
      setUserAddress(account);
      // setUser(account);
      handleRef(account);
    } else {
      alert(
        "Please Install MetaMask: 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn'"
      );
    }
  },[setUserAddress, handleRef]);

  const disconnect = useCallback( () => {
    sessionStorage.clear("meta-address");
    setUserAddress(null);
    setUser(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setUserAddress]);

  useEffect(() => {
    const metaAddress = sessionStorage.getItem("meta-address");
    if (metaAddress) {
      setUserAddress(JSON.parse(metaAddress));
      acc = metaAddress;
    }
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts) {
          if (acc) {
            acc = null;
          }
          disconnect();
        } else if (acc && acc !== accounts[0]) {
          MetamaskHandler();
        }
      });
    }
    return ()=>{
      setUserAddress(null);
    }
    
  },[MetamaskHandler, disconnect]);


  useEffect(() => {
    if (userAddress) {
      setUser(userAddress);
      sessionStorage.setItem("meta-address", JSON.stringify(userAddress));
      setUserAddress(null);
      console.log(userAddress);
    }

  }, [userAddress, setUser]);

  return(
    <>
    {!show2 && <Card className={classes.login}>
      {!userAddress && <button onClick={MetamaskHandler} className={classes.button} type="button">
        {!isConnecting && "Connect to Metamask"}
        {isConnecting && "Loading..."}
      </button> }
    </Card>}
    {/* {show2 && <Signup currentAccount={userAddress}/>} */}
    {show2 && <Signup />}

    </>
 
  );

};

export default Login;
