

import { React , useState , useContext} from "react";
import Card from "../Card/Card";
import classes from "./Sign.module.css";
import "./signup.css";
import { db  } from "../../firebase";
// import { auth } from "../../firebase";
import { Timestamp } from "firebase/firestore";
import { useHistory } from 'react-router-dom';
import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";
import { AuthContext } from "../../context/auth";
// import {
//   RecaptchaVerifier,
//   signInWithPhoneNumber,
// } from "firebase/auth";


const Signup = (props) => {
  

  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [number, setNumber] = useState("");
    const [flag, setFlag] = useState(false);
    const [otp, setOtp] = useState("");
    // const [result, setResult] = useState("");
  const { user , setLoggedin} = useContext(AuthContext);
 

  // const useraddress = user;
  console.log(user)
  
  const history = useHistory();
 

  // const setUpRecaptha = (number) => {
  //   const recaptchaVerifier = new RecaptchaVerifier(
  //     "recaptcha-container",
  //     { 'size': 'invisible','data-badge':'inline'},
  //     auth
  //   );
  //   recaptchaVerifier.render();
  //   return signInWithPhoneNumber(auth, number, recaptchaVerifier);
  // }


  // const getOtp = async (e) => {
  //   e.preventDefault();
  //   console.log(number);
  //   setError("");
  //   if (number === "" || number === undefined)
  //     return setError("Please enter a valid phone number!");
  //   try {
  //     const response = await setUpRecaptha(number);
  //     setResult(response);
    
  //   } catch (err) {
  //     setError(err.message);
  //   }
  // };

  const verifyOtp = async (e) => {
    e.preventDefault();
    setError("");
    if (otp === "" || otp === null)  setFlag(true);
    // try {
    //   await result.confirm(otp);
     
    //   console.log("OTP verified");
    // } catch (err) {
    //   setError(err.message);
    // }
  };

  const handlefname = (e) => {
    setFname(e.target.value);

    console.log(fname);
  };

  const handlelname = (e) => {
    setLname(e.target.value);

    console.log(lname);
  };

  const handleemail = (e) => {
    setEmail(e.target.value);
    console.log(email);
  };
  
  // const handlephone = (e) => {
  //   setPhone(e.target.value);
  //   console.log(phone);
  // };
  

  //handleUser 
  const handleUser = async (e) => {
    e.preventDefault();
    // console.log(useraddress);
    if (!fname || !lname || !email ) {
      return setError("All fields are required")
    }

    try{if ({ user }) {
      console.log(user);
      await db.collection("users").doc(`${user}`).set({
         Firstname: fname,
         Lastname: lname,
         Email: email,
         PhoneNo: number,
         createdAt: Timestamp.fromDate(new Date()),
         isOnline: true,
       });
      setFname("");
      setLname("");
      setEmail("");
      setError("");
      setFlag(false);
      setNumber("");
      setOtp("");
      // setResult("");
       setLoggedin(true);
       alert("Thankyou for signing up");
       history.push('/');
       // window.location.reload();
     }}catch(err){
         console.log(err.message);
     }
  };

  return (
    <>

    <Card className={classes.home} >
       {/* <div><h1>Welcome</h1>
      <p>Your Current Account : </p><p>{props.currentAccount}</p>
      <p>Balance: {props.balance} ETH</p></div> */}
      <div className="modal  border-solid bg-white border-2 " >
     
          <div className="modal-content3 bg-grey"  onClick={(e) => e.stopPropagation()}>
            <div className="modal-header2"  >
              <h1 className="modal-title">Sign Up</h1>
             
              <span className="modal-text2">
                <p>By signing up you agree to all the Terms and conditions.</p>
              </span>
              
              {error && alert(error)}
            </div>
            <form style ={{backgroundColor:"white"}}>
              <input
                className="loginsmall first-left"
                type="text"
                onChange={(e) => handlefname(e)}
                placeholder="First Name"
              ></input>
              <input
                className="loginsmall"
                type="text"
                onChange={(e) => handlelname(e)}
                placeholder="Last Name"
              ></input>

              <input
                className="loginfrom"
                type="email"
                onChange={(e) => handleemail(e)}
                placeholder="Email"
              ></input>
              
              {/* <div className="phone" >
                
             <PhoneInput
              className=""
              type="text"
              defaultCountry="IN"
              value={number}
              onChange={ setNumber }
              placeholder="Enter Phone Number"
              style={{width:"80%"}}
            />
              
               <button className="otp-button loginsmall" type="submit" onClick={getOtp} variant="primary">
                Send OTP
               </button>
               
           
               </div> */}
               
            <div className="verify">
            {/* <input
                className="loginsmall phone"
                type="text"
                onChange={(e) => setOtp(e.target.value)}
                placeholder="Enter OTP"
              ></input> */}
              
               <button  className="otp-verify" type="submit" onClick={verifyOtp} variant="primary">
                Submit
               </button>
            </div>
          
             {flag && <button className="create-btn" onClick={handleUser}>
                Create Account
              </button>}
            </form>
            {/* <div id="recaptcha-container" style={{zIndex:"10000",color:"black",top:"80%",visibility: "hidden"}}>aaaaaaaaaaaaaa</div> */}
          </div>
        </div>
        
      
    </Card>
    
    </>
  );
};
export default Signup;
