import React,{ useContext,useState,useRef} from "react";
//import Attachment from "./svg/Attachment";
//import { CgCloseO } from 'react-icons/cg';
import { AuthContext } from "../context/auth";
//import Modal from '../components/modals/modal';
import Popup from 'reactjs-popup';

import 'reactjs-popup/dist/index.css';

//create nft imports
import Web3 from 'web3';
import axios from 'axios';
import { CONTRACT_ABI, CONTRACT_ADDRESS ,ESCROW_CONTRACT_ABI,
  ESCROW_CONTRACT_ADDRESS} from '../pages/contractconfig'

/*import { uploadFileToIPFS, uploadJSONToIPFS } from "../pinata";
import Marketplace from '../Marketplace.json';*/
//create nft imports
const MessageForm = ({ handleSubmit, text, setText, setImg }) => {
  const {  setImage} = useContext(AuthContext);
 // const { image , setImage} = useContext(AuthContext);
  
/*  function PreviewImage() {
    var oFReader = new FileReader();
    oFReader.readAsDataURL(document.getElementById("img").files[0]);

    oFReader.onload = function (oFREvent) {
        document.getElementById("uploadPreview").src = oFREvent.target.result;
    };
};*/
/* 
//after first line in return
 {image && <div className="imgpreview"><span onClick={()=> setImage(false)} className="closebtn">
                <CgCloseO className="close" />
              </span><img id="uploadPreview" />
              </div>}*/
              const [img,setImg2] = useState();
              
//create nft part
const [formParams, updateFormParams] = useState({ name: '', description: '', price: ''});
   //const [fileURL, setFileURL] = useState(null);
   // const ethers = require("ethers");
    const [message] = useState('');
    //const [message, updateMessage] = useState('');
  //  const [isCreating, setIsCreating] = useState();
   
    //const location = useLocation();
    const [img3, setImg3] = useState();
    const [selectedFile, setSelectedFile] = useState();
  
 //   const [isFilePicked, setIsFilePicked] = useState();
    //This function uploads the NFT image to IPFS
    
    //web3 connection
    const web3 = new Web3(window.ethereum)

    const nftContract = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS)
    function decimalToHex(x) {
      var parsed = parseInt(x, 10);
      if (isNaN(parsed)) { return 0; }
      
      let hexstr = ""
      while(parsed > 0) {
        var value = parsed % 16
        hexstr = (
          value === 10 ? "A" : (
            value === 11 ? "B" : (
            value === 12 ? "C" : (
            value === 13 ? "D" : (
            value === 14 ? "E" : (
            value === 15 ? "F": value)))))) + hexstr
        parsed = parseInt(parsed / 16)
      }
      
      return  hexstr;
    }
    //escrow approve and create sale
    const handleCreateSale = async () => {
   
      const token =tokenId;
      const { price} = formParams;
      let address
      if (typeof window.ethereum != 'undefined') {
        const web3 = new Web3(window.ethereum)
        const accounts = await window.ethereum.request({
          method: 'eth_requestAccounts'
        })
        address = accounts[0]
        console.log('address', address)
        
        let priceeth= web3.utils.toWei(price, 'ether')
        console.log('price and token id', price, token)
    
        const nftContract = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS)
        await nftContract.methods
          .approve(ESCROW_CONTRACT_ADDRESS, token)
          .send({
            from: address
            // gas: 23000
          })
          .on('transactionHash', function () {
            console.log('Transaction Processing............')
            alert(`nft is getting escrowed for sale, wait for confirmation through sale creation metamask popup`)
          })
          .on('receipt', function () {
            console.log('Reciept')
          })
          .on('confirmation', function () {
            console.log('Transaction Confirmed')
            
          })
          .on('error', async function () {
            console.log('Error Encountered')
          })
    
        // const nftContract = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS)
        const escrowContract = new web3.eth.Contract(
          ESCROW_CONTRACT_ABI,
          ESCROW_CONTRACT_ADDRESS
        )
        await escrowContract.methods
          .createSale(token, priceeth)
          .send({
            from: address
            // gas: 23000
          })
          .once('transactionHash', function () {
            console.log('Transaction Processing............')
            alert(`sale is being created, wait for confirmation`)
          })
          .once('receipt', function () {
            console.log('Reciept')
          })
          .once('confirmation', function () {
            console.log('Transaction Confirmed')
             
            alert(`Your Sale Has started here: https://sainftmarketkplace.xyz/details/0x`+tokenIdhex)

           // document.getElementById("message_field").setAttribute('value',"buy my nft here : https://sainft.netlify.app/details/0x"+tokenIdhex) ;
            setText("buy my nft here : https://sainftmarketkplace.xyz/details/0x"+tokenIdhex)
           // window.location.reload();
           closeTooltip();
           
          })
          .once('error', async function () {
            console.log('Error Encountered')
            alert(`Error Encountered!`)
            window.location.reload();
          })
      } else {
        alert(
          "Please Install MetaMask: 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn'"
        )
      }
    }
    //escrow approve and create sale function ends
    //onsubmit,minting
    const onSubmit = async e => {
        e.preventDefault()
      //  setIsCreating(true)
        const {name, description} = formParams;
        const formData = new FormData();
        formData.append('file', selectedFile);
    
        try {
          
    
          const imageHash = await axios.post(
            'https://sainftbackend.onrender.com/api/uploadImage',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
          )
    
          const payload = {
            nftName: name,
            descript: description,
            imgHash: imageHash.data.data
          }
    
          const fileHash = await axios.post(
            'https://sainftbackend.onrender.com/api/createJson',
            payload,
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          )
    
         await mintImage(name, description, fileHash.data.data);
         //console.log(x);
         await handleCreateSale();
        } catch (error) {
          
          console.log('error', error)
        }
      }
   async function OnChangeFile(e) {
        // var file = e.target.files[0];

        const [file2] = e.target.files;
        setImg3(URL.createObjectURL(file2));
        let  files = e.target.files;

  //  setIsFilePicked(true);
    setSelectedFile(files[0]);
        //check for file extension
      /*  try {
            //upload the file to IPFS
            const response = await uploadFileToIPFS(file);
            if(response.success === true) {
                console.log("Uploaded image to Pinata: ", response.pinataURL)
                setFileURL(response.pinataURL);
            }
        }
        catch(e) {
            console.log("Error during file upload", e);
        }*/
    }
  // async function OnChangeFile(e) {
  //   const [file2] = e.target.files;
  //   let files = e.target.files;
  
  //   const allowedTypes = ["image/jpeg", "image/gif", "video/mp4", "image/png"];
  //   if (!allowedTypes.includes(file2.type)) {
  //     // file is not an allowed type, do something (e.g. show an error message)
  //     return;
  //   }
  
  //   if (file2.type.startsWith("image/") && (file2.naturalHeight > 150 || file2.naturalWidth > 100)) {
  //     const image = new Image();
  //     image.src = URL.createObjectURL(file2);
  //     image.onload = function() {
  //       const canvas = document.createElement("canvas");
  //       const context = canvas.getContext("2d");
  //       canvas.width = 10;
  //       canvas.height = 15;
  //       context.drawImage(image, 0, 0, 10, 15);
  //       const resizedImageData = canvas.toDataURL(file2.type);
  //       setImg3(resizedImageData);
  //     }
  //   } else {
  //     setImg3(URL.createObjectURL(file2));
  //   }
  
  //   setSelectedFile(files[0]);
  // }
  
    var tokenId;
    var tokenIdhex;
    const mintImage = async (nftName, descript, uri) => {
        let account
        if (typeof window.ethereum !== 'undefined') {
          const accounts = await window.ethereum.request({
            method: 'eth_requestAccounts'
          })
          // const accounts = await web3.eth.getAccounts();
          account = accounts[0]
        } else {
          alert(
            "Please Install MetaMask: 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn'"
          )
        }
    
        const x = await nftContract.methods
          .mintNFT(account, uri, nftName, descript)
          .send({
            from: account
            // gas: 23000
          })
          .once('transactionHash', function () {
            console.log('Transaction Processing............')
            alert(`NFT is being minted,wait for the confirmation`)
          })
          .once('receipt', function () {
            console.log('Ethereum Reciept')
          })
          .once('confirmation', function () {
            console.log('Transaction Confirmed')
            alert(`Your NFT ${nftName} Has Been minted! Wait for the sale to be created ,dont close this window`)
           // window.location.reload();
          
          })
          .once('error', async function () {
            alert('Ethereum Error Encountered!')
            // window.location.reload()
          })
     
      console.log(x.events.NFTCreated.returnValues.tokenId);
      tokenId = x.events.NFTCreated.returnValues.tokenId;
      console.log(tokenId);
      tokenIdhex=decimalToHex(tokenId);
      
        //setIsCreating(false)
      }

   

    
//var imgsel = selectedFile;
    console.log("Working", process.env);
//create nft part ends
/*const offset = {
  left: 150,
  top: 50,
};  */  

const contentStyle = {
  height: "600px",
  //width: "300px",
  width: "60%",
  borderRadius:"20px"
};
const ref = useRef();
//const openTooltip = () => ref.current.open();
const closeTooltip = () => ref.current.close();
  return (
    <form className="message_form" onSubmit={handleSubmit}  >
     
      
      

      <div >
        <input
          type="text"
          placeholder="Enter message"
          value={text}
          onChange={(e) => setText(e.target.value) }
          style={{color:"black"}}
          id="message_field"
        />
        
      </div>
      <div>
        <button className="btnsend"><img src="send.png" alt="send" /></button>
        
      </div>
      
     <Popup
        ref={ref}
    trigger={<button type="button" className="button2"><img src="attachments.png"  alt="attach"/></button>}
    modal
    contentStyle={contentStyle}
  >
    {close => (
      
      <div className="modal" >
        <button className="close" onClick={close} style={{backgroundColor:"orange",height:"30px",width:"30px",marginTop:"13.5px",marginBottom:"10px",marginLeft:"17px",marginRight:"17px"}}>
          &times;
        </button>
        <div className="header" style={{backgroundColor:"#EB5D3B",borderRadius:"10px"}}> <b style={{color:"white"}}>proceed with the transfer type of your choice</b>   </div>
        <div className="content" style={{display:"flex"}}>
          <div style={{color:"black",marginLeft:"5%",borderRight:"5px solid #EB5D3B",borderRightWidth:"6px"}} >
            <button style={{marginRight:"15%",backgroundColor:"#EB5D3B",borderRadius:"15px",paddingLeft:"14px",paddingRight:"16px",paddingBottom:"8px",paddingTop:"9px"}}><div style={{display:"flex"}}><img src="send.png" alt="send"/> <a href="/createnft" target="_blank" rel="noreferrer" >&nbsp;&nbsp;<span style={{color:"white",fontWeight:"700",fontSize:"16px"}}>Secure</span></a></div></button>
            <div className="" style={{paddingRight:"60px"}}>
       
        <div className="flex flex-col place-items-center mt-1 " id="nftForm">
            <form className="bg-white shadow-md rounded px-1 pt-1 pb-1 mb-1">
            
                <div className="mb-4">
                    <label className="block text-black-500 text-sm font-bold mb-1" htmlFor="name">NFT Name</label>
                    <input className="shadow bg-orange-200 appearance-none border rounded w-full py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="your NFT" onChange={e => updateFormParams({...formParams, name: e.target.value})} value={formParams.name}></input>
                </div>
                <div className="mb-6">
                    <label className="block text-black-500 text-sm font-bold mb-1" htmlFor="description">NFT Description</label>
                    <textarea className="shadow bg-orange-200 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" cols="40" rows="2" id="description" type="text" placeholder="SaiNFT collection" value={formParams.description} onChange={e => updateFormParams({...formParams, description: e.target.value})}></textarea>
                </div>
                <div className="mb-6">
                    <label className="block text-black-500 text-sm font-bold mb-1" htmlFor="price">Price (in MATIC)</label>
                    <input className="shadow  bg-orange-200 appearance-none border rounded w-full py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="number" placeholder="Min 0.01 ETH" step="0.01" value={formParams.price} onChange={e => updateFormParams({...formParams, price: e.target.value})}></input>
                </div>
                <div className="w-55 h-48">

                    <label className="block text-black-500 text-sm font-bold mb-1" htmlFor="image">Upload Image</label>
                    <input type={"file"} onChange={OnChangeFile}></input>
                    <img src={img3} alt="" className=" max-w-100  h-full object-fit-cover" />
                    
                </div>
                <br></br>
                <div className="text-green text-center">{message}</div>
                <button onClick={onSubmit} className="font-bold bg-orange-400 mt-3 w-full  text-white rounded p-2 shadow-lg">
                    List NFT
                </button>
            </form>
        </div>
        </div>
            </div>
             <div style={{marginLeft:"0px",color:"black",marginRight:"10px"}}>
             <div style={{marginLeft:"80px",width:"120px",display:"flex",backgroundColor:"#EB5D3B",borderRadius:"15px",paddingLeft:"14px",paddingRight:"10px",paddingBottom:"8px",paddingTop:"9px"}}><img src="send.png" alt="send"/> &nbsp;&nbsp;<span style={{color:"white",fontWeight:"700",fontSize:"16px"}}>Normal</span></div>
            <br/> <br/> <br/> <label htmlFor="myFile" style={{marginLeft:"40px",marginRight:"15%",backgroundColor:"#EB5D3B",borderRadius:"15px",paddingLeft:"14px",paddingRight:"16px",paddingBottom:"8px",paddingTop:"9px"}}>
              <span style={{color:"white",fontWeight:"400",fontSize:"16px"}}>&nbsp; choose file</span>
      </label>
    <input type="file" id="myFile"  onChange={(e) => {setImg(e.target.files[0]) 
          setImage(true)
          const [file] = e.target.files;
    setImg2(URL.createObjectURL(file));
         
      }} style={{ visibility:"hidden"}}/>
      <div className="w-48">
       <img src={img} alt="" style={{marginTop:"20px",marginLeft:"80px"}}  className=" max-w-100  h-full " />
       </div>
             </div>

        </div>
        <div className="actions">
         
          <button
            className="button3"
            onClick={() => {
              console.log("modal closed ");
              close();
            }}
            style={{bottom:"10%"}}
          >
            close 
          </button>
        </div>
      </div>
    )}
      
  </Popup>
    </form>
    
  );
};

export default MessageForm;
